import { React, SEO, ImageYo, styled } from '../shipityo'

import Layout from "../components/layout"
import PageHeading from "../components/pageheading"

const PeopleBox = styled.div`
  margin-bottom: 50px;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  @media only screen 
    and (min-width: 500px) 
    and (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
  } 
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 499px) {
      grid-template-columns: 1fr;
  } 

`;

const PersonCard = styled.div`
  display: block;
  padding-left: 9px;
  padding-right: 9px;
  div {
    margin-top: 7px;
    margin-bottom: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
    background: #B3B3B3;
  }
`;

const Icon = styled.div`
  transition-duration: all 2s;
  .material-icons{
    background: transparent;
    font-size: 100px;
    -webkit-box-shadow: 6px 5px 16px 5px #000000; 
    box-shadow: 6px 5px 16px 5px #000000;
    border-radius: 190px;
  }
  color: green;
  .material-icons:hover{
    color: blue;
    -webkit-box-shadow: 6px 5px 40px 5px #000000; 
    box-shadow: 6px 5px 40px 5px #000000;
  }
`;

const Page= () => (
  <Layout>
    <SEO 
      title="Welcome | Mount Everest Indian Restaurant" 
      keywords={[
        "Mount Everest Indian Restaurant", 
        "Indian Restaurant", 
        "Indian Restaurant Hermanus", 
        "Hermanus Indian Restaurant", 
        "Restaurant Hermanus"
      ]}  
    />
    <PageHeading PageHeading="Welcome"></PageHeading>
    
    <center>
    <p>This is the finest quality Indian Food Restaurant in Hermanus. Come try our food and we guarantee you will be back for more. We look forward to seeing you for your next Indian food order.</p>
    <h3>See you soon!</h3>
    </center>

    <PeopleBox>
      <PersonCard> 
        <ImageYo src="landingimage1.jpg"/>
      </PersonCard>
      <PersonCard> 
        <ImageYo src="landingimage2.jpg"/>
      </PersonCard>
      <PersonCard> 
        <ImageYo src="landingimage3.jpg"/>
      </PersonCard>
    </PeopleBox>
    

  </Layout>
)

export default Page
